var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-form-group",
    { attrs: { invalid: _vm.error !== null } },
    [
      _c(
        "gov-label",
        { staticClass: "govuk-!-font-weight-bold", attrs: { for: _vm.id } },
        [
          _vm._t("label", function() {
            return [_vm._v(_vm._s(_vm.label))]
          })
        ],
        2
      ),
      _vm._t("hint", function() {
        return [
          _vm.hint
            ? _c("gov-hint", {
                attrs: { for: _vm.id },
                domProps: { textContent: _vm._s(_vm.hint) }
              })
            : _vm._e()
        ]
      }),
      _c(
        "gov-radios",
        [
          _c("gov-radio", {
            attrs: {
              "bind-to": _vm.value,
              id: _vm.id + "_enabled",
              name: _vm.id,
              label: "Enabled",
              value: true
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            }
          }),
          _c("gov-radio", {
            attrs: {
              "bind-to": _vm.value,
              id: _vm.id + "_disabled",
              name: _vm.id,
              label: "Disabled",
              value: false
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }